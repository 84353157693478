/*
 * @Description: app更新配置
 * @Author: yaoxiaoxiao
 * @RearEnd:
 * @Date: 2021-07-08 10:27:33
 * @LastEditors: yaoxiaoxiao
 * @LastEditTime: 2021-08-30 16:53:42
 */

import { enumToOption } from '@/utils'

// 所属app
export enum appEnum {
  司机端 = 1,
  司管端
}
// 应用类型
export enum osType {
  Android = 1,
  iOS
}
// 更新类型
export enum upgradeMode {
  选择更新 =1,
  强制更新
}
// 状态
export enum status {
  失效,
  生效
}

export const appOption = enumToOption(appEnum)
export const osTypeOption = enumToOption(osType)
export const upgradeModeOption = enumToOption(upgradeMode)
export const statusOption = enumToOption(status)

// 搜索配置
export const inputList = () => [
  {
    type: 'select',
    label: '所属app',
    key: 'app',
    placeholder: '请选择',
    labelWidth: 100,
    inputWidth: 200,
    options: appOption
  },
  {
    type: 'select',
    label: '应用类型',
    key: 'osType',
    placeholder: '请选择',
    labelWidth: 100,
    inputWidth: 200,
    options: osTypeOption
  },
  {
    type: 'input',
    label: 'app版本号',
    key: 'appVersion',
    placeholder: '如：1.0.2',
    labelWidth: 100,
    inputWidth: 200
  },
  {
    type: 'select',
    label: '更新类型',
    key: 'upgradeMode',
    placeholder: '请选择',
    labelWidth: 100,
    inputWidth: 200,
    options: upgradeModeOption
  },
  {
    type: 'select',
    label: '状态',
    key: 'status',
    placeholder: '请选择',
    labelWidth: 100,
    inputWidth: 200,
    options: statusOption,
    rules: {
      type: 'number'
    }
  }
]

// 表格配置
export const getTableConfig = () => {
  // table 通用属性
  const common = {
    // table 有多选时设置
    isSelection: false,
    // table loading
    loading: false,
    // table 是否为斑马纹
    stripe: false,
    // table 的尺寸 medium / small / mini
    size: 'small',
    // table 是否开启索引
    isIndex: true,
    // table 索引 label
    indexLabel: '编号',
    // table 数据
    data: [{}]
  }

  // table 分页
  const pagination = {
    // 是否展示分页
    isShow: true,
    // 总条数
    total: 0,
    // 每页显示条目个数
    pageSize: 20,
    // 当前页数
    currentPage: 1,
    // 每页显示个数选择器的选项设置
    pageSizes: [10, 20, 40, 80, 100]
  }

  // table 列数据
  const column = [
    {
      key: 'app',
      label: '所属app',
      width: '120',
      slot: 'app'
    },
    {
      key: 'osType',
      label: '应用类型',
      width: '120',
      slot: 'osType'
    },
    {
      key: 'appVersion',
      label: 'app版本号',
      width: '120'
    },
    {
      key: 'upgradeMode',
      label: '更新类型',
      width: '120',
      slot: 'upgradeMode'
    },
    {
      key: 'status',
      label: '状态',
      tooltip: true,
      width: '100',
      slot: 'status'
    },
    {
      key: 'creatorName',
      label: '创建人',
      width: '120'
    },
    {
      key: 'createTime',
      label: '创建时间',
      width: '150'
    },
    {
      key: 'updateTime',
      label: '修改时间',
      width: '150'
    },
    {
      key: 'action',
      label: '操作',
      width: '120',
      fixed: 'right',
      slot: 'action'
    }
  ]

  return {
    ...common,
    pagination,
    column
  }
}


import { addAPI, detailAPI, editAPI } from '../api'
import {
  defineComponent,
  reactive,
  readonly,
  ref,
  toRefs,
  watch,
  watchEffect
} from 'vue'
import CUpload from '@/components/c-upload'
import { ElMessage } from 'element-plus'
import { toResult } from '@/utils/toResult'
import { setBaseUrl } from '@/config/url'
import { getToken } from '@/utils/auth'
import {
  appOption,
  osTypeOption,
  upgradeModeOption,
  statusOption
} from '../config'
export default defineComponent({
  name: '',
  props: {
    modelValue: {
      type: Boolean,
      required: true,
      default: false
    },
    row: {
      type: Object,
      default: () => {}
    },
    isType: {
      type: String,
      default: 'edit'
    }
  },
  components: { CUpload },
  setup(props, { emit }) {
    const required = {
      required: true,
      message: '必填',
      trigger: ['blur', 'change']
    }

    const refFrom = ref<HTMLElement | any>(null)

    const state = reactive({
      visible: false,
      loading: false,
      isView: false,
      isUpload: true,
      title: '添加App更新',
      formData: ref<any>({}),
      rules: {
        app: [required],
        osType: [required],
        appVersion: [required],
        upgradeMode: [required],
        description: [required],
        appFile: [required],
        status: [required]
      },
      fileList: [],
      appOption,
      osTypeOption,
      upgradeModeOption,
      statusOption
    })

    const readonlyData = readonly({
      action:
        setBaseUrl().uploadUrl + '/ccclubs-file-center/common/file/uploadFile',
      headers: {
        token: getToken()
      },
      accept: '.apk',
      limit: 1
    })

    watchEffect(() => {
      state.visible = props.modelValue
    })

    watch(() => state.formData.osType, value => {
      if (value === 2) {
        state.isUpload = false
      } else {
        state.isUpload = true
      }
    })

    /**
     * @description: 异步获取详情
     * @param {*}
     * @return {*}
     */
    const getDetail = async() => {
      const [err, res] = await toResult(detailAPI({ id: props.row.id }))
      if (err) return
      console.log(res)
      state.formData = {
        ...res
      }
      res.osType === 1 && (state.fileList = [{ name: res.appFile, url: res.appFile }])
    }

    ;(async () => {
      switch (props.isType) {
        case 'edit':
          state.title = '修改App更新'
          getDetail()
          break
        case 'view':
          state.title = 'App更新'
          state.isView = true
          getDetail()
          break
      }
    })()

    /**
     * @description: 取消（关闭）当前 dialog
     * @param {*} void
     * @return {*}
     */
    const onCancel = (): void => {
      state.visible = false
      state.formData = {}
      emit('update:modelValue', false)
      emit('update:list')
    }

    /**
     * @description: 表单提交
     * @param {*}
     * @return {*}
     */
    const onSubmit = async p => {
      let requestAPI = addAPI
      if (props.isType === 'edit') requestAPI = editAPI
      const [err, res, msg] = await toResult(requestAPI(p))
      if (err) return
      console.log(res)
      ElMessage.success(msg || '成功')
      onCancel()
    }

    /**
     * @description: 表单提交前校验
     * @param {*}
     * @return {*}
     */
    const onValidate = () => {
      console.log(refFrom.value)
      refFrom.value.validate((valid: boolean) => {
        if (!valid) return
        onSubmit({
          ...state.formData
        })
      })
    }

    /**
     * @description: 图片上传成功回调
     * @param {*} file
     * @return {*}
     */
    const onUploadSuccess = (file) => {
      console.log(file)
      state.formData.appFile = file.response.data.filename
    }

    /**
     * @description: 图片删除成功回调
     * @param {*} file
     * @return {*}
     */
    const onUploadRemove = (file) => {
      console.log(file)
      state.formData.appFile = ''
    }

    return {
      ...toRefs(state),
      ...toRefs(readonlyData),
      onCancel,
      onValidate,
      refFrom,
      onUploadSuccess,
      onUploadRemove
    }
  }
})


import { getListAPI } from './api'
import { btnName } from '@/utils/index'
import CSearchList from '@/components/c-search-list'
import CTable from '@/components/c-table'
import { defineComponent, reactive, toRefs } from 'vue'
import {
  getTableConfig,
  inputList,
  appEnum,
  osType,
  upgradeMode,
  status
} from './config'
import { toResult } from '@/utils/toResult'
import { usePagination } from '@/hooks/usePagination'
import Dialog from './components/dialog.vue'
export default defineComponent({
  name: '',
  components: { CSearchList, CTable, Dialog },
  setup() {
    // 组件全局响应状态
    const state = reactive({
      inputList: inputList(),
      dataSource: getTableConfig(),
      searchParams: null,
      isShow: false,
      currentRow: {},
      countData: {},
      // add or edit or view
      isType: 'eidt',
      appEnum,
      osType,
      upgradeMode,
      status
    })
    /**
     * @description: 异步获取列表数据
     * @param {*}
     * @return {void}
     */
    const getList = async (params: object): Promise<void> => {
      state.dataSource.loading = true
      const p = {
        currPage: state.dataSource.pagination.currentPage,
        pageSize: state.dataSource.pagination.pageSize,
        ...params
      }
      const [err, data] = await toResult(getListAPI(p))
      state.dataSource.loading = false
      if (err) return
      state.dataSource.data = data.list || []
      state.dataSource.pagination.total = data.total || 0
    }

    /**
     * @description: 执行搜索
     * @param {object} params
     * @return {void}
     */
    const handleSearch = (params: { createTime?: any[] } | null): void => {
      state.dataSource.pagination.currentPage = 1
      state.searchParams = { ...params }
      getList(state.searchParams)
    }

    // setup 时候执行一次
    handleSearch(null)

    /**
     * @description: 执行重置
     * @param {object} params
     * @return {void}
     */
    const handleReset = (params: object): void => {
      state.searchParams = params
    }

    // c-table pagination method hook
    const { pageCurrentChange, pageSizeChange } = usePagination(
      state.dataSource.pagination,
      () => {
        getList(state.searchParams)
      }
    )

    /**
     * @description: 切换显示组件
     * @param {*} attr 控制组件显示的属性名称
     * @param {*} row 选中列表的行数据
     * @param {*} type 是编辑还是查看 edit || view
     * @return {*}
     */
    const onToggle = (attr: string, row?, type?): void => {
      state[attr] = !state[attr]
      row && (state.currentRow = row)
      type && (state.isType = type)
    }

    return {
      ...toRefs(state),
      btnName,
      pageSizeChange,
      pageCurrentChange,
      handleSearch,
      handleReset,
      onToggle
    }
  }
})

/*
 * @Description:
 * @Author: yaoxiaoxiao
 * @RearEnd:
 * @Date: 2021-07-08 10:43:57
 * @LastEditors: yaoxiaoxiao
 * @LastEditTime: 2021-08-10 14:48:41
 */
import service from '@/utils/request'

// 主列表
const LIST_PATH = '/admin/system/appUpgrade/page'
const ADD_PATH = '/admin/system/appUpgrade/add'
const EDIT_PATH = '/admin/system/appUpgrade/edit'
const DETAIL_PATH = '/admin/system/appUpgrade/detail'

/**
* @description: 列表分页查询
* @param {any} data
* @return {object}
*/
export const getListAPI = (data: any): object => {
  return service.request({
    url: LIST_PATH,
    method: 'post',
    data
  })
}
/**
* @description: 添加
* @param {any} data
* @return {object}
*/
export const addAPI = (data: any): object => {
  return service.request({
    url: ADD_PATH,
    method: 'post',
    data
  })
}
/**
* @description: 编辑
* @param {any} data
* @return {object}
*/
export const editAPI = (data: any): object => {
  return service.request({
    url: EDIT_PATH,
    method: 'post',
    data
  })
}

/**
* @description: 详情
* @param {any} data
* @return {object}
*/
export const detailAPI = (data: any): object => {
  return service.request({
    url: DETAIL_PATH,
    method: 'post',
    data
  })
}
